import React, { useState } from 'react';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Box, Typography,
} from '@mui/material';
import { useFunctions } from 'reactfire';
import { useFormik } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { httpsCallable } from 'firebase/functions';
import { logEvent, getAnalytics } from 'firebase/analytics';
import { LoadingButton } from '@mui/lab';

function AddMemberButton({ organizationId, onAdded }) {
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const analytics = getAnalytics();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Invalid email address';
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const inviteOrganizationMember = httpsCallable(functions, 'inviteOrganizationMemberCallM2');
        logEvent(analytics, 'add_new_member', {
          appName: 'Organizations-WS',
          organization: globalState.activeOrganization,
        });
        const result = await inviteOrganizationMember({ organizationId, email: values.email });
        onAdded(result.data);
        globalActions.setSnackbarMessage({ message: t('organizations.invite_sent'), severity: 'success' });
      } catch (error) {
        globalActions.setSnackbarMessage({ message: `Error inviting member: ${error.message}`, severity: 'error' });
      } finally {
        formik.handleReset();
        setLoading(false);
        setDialogOpen(false);
      }
    },
  });

  const handleAddClick = () => {
    setDialogOpen(true);
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Paper
        sx={{
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
          cursor: 'pointer',
        }}
        onClick={handleAddClick}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          gap: 1,
        }}
        >
          <AddIcon fontSize="large" />
          <Typography component="span" sx={{ maxWidth: '75%' }}>
            {t('organizations.invite')}
          </Typography>
        </Box>
      </Paper>
      <Dialog open={dialogOpen} onClose={handleCancel}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>{t('organizations.invite')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              name="email"
              label="Email"
              type="email"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.email)}
              helperText={formik.errors.email}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              {t('cancel')}
            </Button>
            <LoadingButton
              loading={loading}
              type="submit"
              color="primary"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {t('add')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default AddMemberButton;
