import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useFirestore, useUser } from 'reactfire';
import { doc, getDoc } from 'firebase/firestore';
import OrganizationAdminView from 'components/organizations/admin/Organization';
import OrganizationMemberView from 'components/organizations/member/Organization';

export default function Organization(props) {
  const location = useLocation();
  const [organization] = useState(
    location?.state?.organization,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!organization) {
      navigate('/');
    }
  }, [navigate, organization]);

  if (!organization) {
    return null;
  }
  return (<OrganizationContent organization={organization} />);
}

function OrganizationContent({ organization }) {
  const [loadingState, setloadingState] = useState('initial');
  const [member, setmember] = useState(undefined);
  const db = useFirestore();
  const { data: user } = useUser();
  useEffect(() => {
    async function loadMember() {
      const docRef = doc(db, 'organizations', organization.id, 'members', user.uid);
      const colSnap = await getDoc(docRef);
      loadUser({ ...colSnap.data(), id: user.uid });
    }

    async function loadUser(firstVersionMember) {
      const udocRef = doc(db, 'users', user.uid);
      const udocSnap = await getDoc(udocRef);
      loadMembership({ ...firstVersionMember, email: udocSnap.data().email });
    }

    async function loadMembership(secondVersionMember) {
      const udocRef = doc(db, 'organizations', organization.id, 'members', user.uid, 'data', 'membership');
      const udocSnap = await getDoc(udocRef);
      setmember({ ...secondVersionMember, membershipState: udocSnap.data().membershipState });
      setloadingState('loaded');
    }
    if (loadingState === 'initial' && user?.uid !== undefined) {
      setloadingState('loading');
      loadMember();
    }
  }, [db, loadingState, organization.id, user?.uid]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        width: '100%',
      }}
    >
      <Typography component="span" sx={{ fontWeight: 'bold' }} variant="h3">{organization.name}</Typography>
      {loadingState !== 'loaded' && (
        <CircularProgress />
      )}
      {loadingState === 'loaded' && member?.role === 'admin' && (
        <OrganizationAdminView organization={organization} />
      )}
      {loadingState === 'loaded' && member?.role !== 'admin' && (
        <OrganizationMemberView organization={organization} member={member} />
      )}
    </Box>
  );
}
