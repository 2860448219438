import React from 'react';
import './App.css';
import 'fontsource-roboto';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import { Container } from '@mui/material';
import {
  useFirebaseApp,
  AuthProvider,
  AnalyticsProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  RemoteConfigProvider,
  useInitRemoteConfig,
  useInitFirestore,
} from 'reactfire';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';
import CssBaseline from '@mui/material/CssBaseline';
import { initializeFirestore } from 'firebase/firestore';
import LoadingPage from 'components/LoadingPage';
import Home from './components/Home';
import { initI18next } from './i18n/i18next';

const firebaseConfig = {
  apiKey: 'AIzaSyCa9rLf4xmacQg6xdoxOUxrWFrz7N_34W4',
  authDomain: 'orgs-ws.labox-apps.com',
  projectId: 'labox-ws',
  storageBucket: 'labox-ws.appspot.com',
  messagingSenderId: '669483452439',
  appId: '1:669483452439:web:643c9f04e45b710f297452',
  measurementId: 'G-5N6TZZE0L8',
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#437A89',
      contrastText: 'white',
    },
    secondary: {
      main: '#73a9b9',
      contrastText: 'white',
    },
    text: {
      primary: '#3f4d5a',
    },
    info: {
      main: '#437A89',
      contrastText: 'white',
    },
    ourGreen: {
      main: '#99BD5B',
      contrastText: 'white',
    },
    ourOrange: {
      main: '#f4ad45',
      contrastText: 'white',
    },
    gold: {
      main: '#FFD700',
      contrastText: 'white',
    },
    darkgray: {
      main: '#0000008a',
      contrastText: 'white',
    },
    mediumgray: {
      main: '#8585858a',
      contrastText: 'white',
    },
    gray: {
      main: '#d8d8d8',
      contrastText: 'white',
    },
  },
});

function AllReactFireProviders() {
  const app = useFirebaseApp();
  const auth = getAuth(app);

  if (process.env.NODE_ENV === 'development') {
    connectAuthEmulator(auth, 'http://localhost:9099');
  }

  return (
    <AnalyticsProvider sdk={getAnalytics(app)}>
      <AuthProvider sdk={auth}>
        <ProvidersWithLoading />
      </AuthProvider>
    </AnalyticsProvider>
  );
}

function ProvidersWithLoading() {
  const { status: firestoreStatus, data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
    const savedSetting = localStorage.getItem('forceLongPolling');
    const forceLongPolling = savedSetting === 'true';
    const baseSettings = {};

    if (forceLongPolling) {
      baseSettings.experimentalForceLongPolling = true;
    } else {
      baseSettings.experimentalAutoDetectLongPolling = true;
    }
    const fsSettings = (process.env.NODE_ENV === 'development')
      ? {
        ...baseSettings,
        host: 'localhost:4401',
        ssl: false,
      } : baseSettings;

    const db = initializeFirestore(firebaseApp, fsSettings);
    return db;
  });

  const { status: remoteConfigStatus, data: remoteConfigInstance } = useInitRemoteConfig(async (firebaseApp) => {
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 600000,
      fetchTimeoutMillis: 600000,
    };

    try {
      await fetchAndActivate(remoteConfig);
    } catch (e) {
      console.warn('Remote config failed to be activated');
    }

    return remoteConfig;
  });

  if (firestoreStatus === 'loading' || remoteConfigStatus === 'loading') {
    return (
      <LoadingPage />
    );
  }

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <AllFirebaseProviders
        remoteConfigInstance={remoteConfigInstance}
      />
    </FirestoreProvider>
  );
}

function AllFirebaseProviders(props) {
  const { remoteConfigInstance } = props;
  const app = useFirebaseApp();
  const functionsInstance = getFunctions(app);

  if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(functionsInstance, 'localhost', 5001);
  }

  return (
    <FunctionsProvider sdk={functionsInstance}>
      <RemoteConfigProvider sdk={remoteConfigInstance}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Container>
              <CssBaseline />
              <Home />
            </Container>
          </ThemeProvider>
        </StyledEngineProvider>
      </RemoteConfigProvider>
    </FunctionsProvider>
  );
}

function App() {
  initI18next();
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <div className="App" style={{ whiteSpace: 'pre-line' }}>
        <AllReactFireProviders />
      </div>
    </FirebaseAppProvider>
  );
}

export default App;
