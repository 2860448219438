import React, { useState, useEffect } from 'react';
import {
  useFirestore, useFunctions,
} from 'reactfire';
import {
  doc, updateDoc, arrayUnion, arrayRemove,
} from 'firebase/firestore';
import {
  Paper, Typography, Button, Dialog, CircularProgress,
  DialogContent, DialogTitle,
  List, ListItem, ListItemText, TextField, IconButton, DialogActions,
  Link as MUILink,
} from '@mui/material';
import { useFormik } from 'formik';
import useGlobal from 'global-state/store';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { httpsCallable } from 'firebase/functions';
import { LoadingButton } from '@mui/lab';

export default function OrgApp({ organizationId, app, members }) {
  const firestore = useFirestore();
  const [admins, setAdmins] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadingState, setloadingState] = useState('initial');
  const [, globalActions] = useGlobal();
  const { t } = useTranslation();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const addMemberAsAppAdminCallable = httpsCallable(functions, 'addMemberAsAppAdmin');
  const [callLoading, setCallLoading] = useState(false);

  useEffect(() => {
    async function loadAdmins() {
      const adminIds = app?.admins || [];
      const newAdmins = adminIds.map((id) => ({ id, email: members.find((m) => m.id === id).email }));
      setAdmins(newAdmins);
      setloadingState('loaded');
    }

    if (loadingState === 'initial') {
      setloadingState('loading');
      loadAdmins();
    }
  }, [app?.admins, firestore, loadingState, members, organizationId]);

  // Handler for removing an admin
  const handleRemoveAdmin = async (adminId) => {
    const appDocRef = doc(firestore, 'organizations', organizationId, 'apps', app.id);
    await updateDoc(appDocRef, { admins: arrayRemove(adminId) });
    const newAdmins = admins.filter((a) => a.id !== adminId);
    setAdmins(newAdmins);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Invalid email address';
      } else if (!members.some((m) => m.email === values.email)) {
        errors.email = 'The admin must be one of the members of the organization';
      }
      return errors;
    },
    onSubmit: async (values) => {
      setCallLoading(true);
      try {
        const { email } = values;
        const addAdminResult = await addMemberAsAppAdminCallable({
          email,
          organizationId, // Assuming this is available in the scope
          appId: app.id, // Assuming this is available in the scope
        });

        // Assuming the Cloud Function returns the new admin's UID in newAdminId
        const newAdminUid = addAdminResult.data.newAdminId;

        // Find the member with the returned UID to update the local state
        const member = members.find((m) => m.id === newAdminUid);
        const newAdmins = [...admins, member];
        setAdmins(newAdmins);
        globalActions.setSnackbarMessage({ message: t('success'), severity: 'success' });
      } catch (error) {
        globalActions.setSnackbarMessage({ message: `Error adding admin: ${error.message}`, severity: 'error' });
      } finally {
        setCallLoading(false);
        setDialogOpen(false);
      }
    },
  });

  if (loadingState !== 'loaded') {
    return <CircularProgress />;
  }

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 1,
      }}
    >
      <Typography component="span" sx={{ fontWeight: 'bold' }}>{app.name}</Typography>
      <MUILink
        href={app.link}
        color="primary"
        sx={{ fontWeight: 'bold', fontSize: 12 }}
      >
        {t('to_app')}
      </MUILink>
      <Typography component="span" sx={{ fontSize: 14, alignSelf: 'flex-start', mt: 1 }}>Admins :</Typography>
      <List sx={{ p: 0 }}>
        {admins.map((admin) => (
          <ListItem
            key={admin.id}
            disablePadding
            dense
          >
            <ListItemText primary={admin.email} />
            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveAdmin(admin.id)} size="small">
              <DeleteIcon />
            </IconButton>
            {/* <ListItemIcon /> */}
          </ListItem>
        ))}
        <Button variant="outlined" color="primary" size="small" onClick={() => setDialogOpen(true)} sx={{ mt: 1 }}>
          {t('organizations.add_admin')}
        </Button>
      </List>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>{t('organizations.add_admin')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              name="email"
              label="Email"
              type="email"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.email)}
              helperText={formik.errors.email}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} color="primary">
              {t('cancel')}
            </Button>
            <LoadingButton
              loading={callLoading}
              type="submit"
              color="primary"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {t('add')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Paper>
  );
}
