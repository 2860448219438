import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MyMembership from '../MyMembership';
import OrgApps from './OrgApps';

export default function Organization({ organization, member }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        p: 3,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: 3,
      }}
    >
      <Typography component="span" variant="h4">Applications</Typography>
      <OrgApps organizationId={organization.id} member={member} />
      <Typography component="span" variant="h4">{t('organizations.membership')}</Typography>
      <MyMembership organizationId={organization.id} member={member} />
    </Box>
  );
}
