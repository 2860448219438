import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useFirestore } from 'reactfire';
import { doc, setDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

export default function MemberRoleSelect(props) {
  const [editing, setEditing] = useState(false);
  const {
    defaultValue, organizationId, userId, userExists,
  } = props;
  const [value, setvalue] = useState(defaultValue);
  const db = useFirestore();
  const { t } = useTranslation();

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = async () => {
    if (userExists) {
      const docRef = doc(db, 'organizations', organizationId, 'members', userId);
      await setDoc(docRef, { role: value }, { merge: true });
    } else {
      const docRef = doc(db, 'pendingOrganizationInvitations', userId);
      await setDoc(docRef, { role: value }, { merge: true });
    }
    setEditing(false);
  };

  const handlevalueChange = (event) => {
    setvalue(event.target.value);
  };

  return (
    <Box>
      {editing ? (
        <Box sx={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1,
        }}
        >
          <Select value={value} onChange={handlevalueChange} size="small">
            <MenuItem value="member">Member</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
          <IconButton size="small" aria-label="add an alarm" onClick={handleSaveClick}>
            <CheckIcon fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <Box sx={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1,
        }}
        >
          <Typography variant="body1">
            {t(`member_role.${value}`)}
          </Typography>
          <IconButton size="small" aria-label="add an alarm" onClick={handleEditClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
