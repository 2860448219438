import React from 'react';
import Box from '@mui/material/Box';
import LaboxWS from 'icons/LaboxWS';
import { CircularProgress } from '@mui/material';
import Footer from './Footer';

export default function LoadingPage() {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LaboxWS width="180" />
      <CircularProgress />
      <Footer minimal />
    </Box>
  );
}
