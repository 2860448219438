import React from 'react';
import {
  Paper, Typography,
  List, ListItem, ListItemText,
  Link as MUILink,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function OrgApp({ organizationId, app, member }) {
  const { t } = useTranslation();
  const isAdmin = app.admins?.includes(member.id) || false;

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 1,
      }}
    >
      <Typography component="span" sx={{ fontWeight: 'bold' }}>{app.name}</Typography>
      <MUILink
        href={app.link}
        color="primary"
        sx={{ fontWeight: 'bold', fontSize: 12 }}
      >
        {t('to_app')}
      </MUILink>
      <List>
        <ListItem key={member.id}>
          <ListItemText primary={isAdmin ? t('organizations.you_are_admin') : t('organizations.you_are_not_admin')} />
        </ListItem>
      </List>
    </Paper>
  );
}
