import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useFirestore } from 'reactfire';
import {
  doc, getDoc, writeBatch, arrayRemove,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import {
  Dialog, DialogContent, DialogActions,
} from '@mui/material';
import useGlobal from 'global-state/store';

export default function MyMembership(props) {
  const { t } = useTranslation();
  const { organizationId, member } = props;
  const db = useFirestore();
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [, globalActions] = useGlobal();

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const quitOrganization = async () => {
    try {
      const userPrivateDataRef = doc(db, 'users', member.id, 'private', 'data');
      const memberRef = doc(db, 'organizations', organizationId, 'members', member.id, 'data', 'membership');

      const userPrivateData = await getDoc(userPrivateDataRef);

      let activeOrganization = userPrivateData.activeOrganization || '';
      if (activeOrganization === organizationId) {
        activeOrganization = '';
      }

      const batch = writeBatch(db);

      batch.update(userPrivateDataRef, {
        organizations: arrayRemove(organizationId),
        activeOrganization,
      });
      batch.update(memberRef, {
        membershipState: 'quit',
      });
      await batch.commit();
      await sleep(2000);
      navigate('/');
    } catch (error) {
      globalActions.setSnackbarMessage({
        message: `Error quitting the organisation: ${error.message}`,
        severity: 'error',
      });
    } finally {
      setConfirmOpen(false);
    }
  };

  return (
    <Paper
      sx={{
        width: '100%',
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
      }}
    >
      <Typography component="span" sx={{ fontWeight: 'bold' }}>{member.email}</Typography>
      <Typography component="span">{`${t('organizations.role')}: ${member.role}`}</Typography>
      <Button variant="outlined" size="small" color="error" onClick={() => setConfirmOpen(true)}>
        {t('organizations.quit')}
      </Button>
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogContent>
          {t('organizations.quit_confirm')}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            {t('no')}
          </Button>
          <Button onClick={quitOrganization} color="error" autoFocus>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
