import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useGlobal from 'global-state/store';
import CircularProgress from '@mui/material/CircularProgress';
import { useFirestore, useUser } from 'reactfire';
import {
  doc, getDoc,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import CreateNewOrganizationButton from './CreateNewOrganizationButton';
import OrganizationInvitationCard from './OrganizationInvitationCard';

export default function Organizations(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const { data: user } = useUser();
  const [loadingState, setloadingState] = useState('initial');
  const db = useFirestore();
  const [orgaInvitations, setorgaInvitations] = useState([]);

  useEffect(() => {
    async function loadUser() {
      try {
        const udocRef = doc(db, 'users', user.uid, 'private', 'data');
        const udocSnap = await getDoc(udocRef);
        setorgaInvitations(udocSnap.data()?.organizationInvitations || []);
        setloadingState('loaded');
      } catch (err) {
        globalActions.setSnackbarMessage({ message: `${t('unexpected_error')}: ${err.message}`, severity: 'error' });
      }
    }
    if (loadingState === 'initial' && user?.uid !== undefined) {
      setloadingState('loading');
      loadUser();
    }
  }, [db, globalActions, loadingState, t, user?.uid]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        width: '100%',
      }}
    >
      <Typography component="span" sx={{ fontWeight: 'bold' }} variant="h6">{t('organizations.mine')}</Typography>
      {loadingState === 'loaded'
      && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'stretch',
          justifyContent: 'center',
          gap: 2,
          width: '100%',
        }}
      >
        {globalState.organizations.map((org) => (
          <OrganizationCard organizationId={org} key={org} />
        ))}
        <CreateNewOrganizationButton onCreate={() => globalActions.setUserStatus('initial')} />
      </Box>
      )}
      <Typography component="span" sx={{ fontWeight: 'bold' }} variant="h6">
        {t('organizations.invitations')}
      </Typography>
      {loadingState === 'loaded'
      && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'stretch',
          justifyContent: 'center',
          gap: 2,
          width: '100%',
        }}
      >
        {orgaInvitations.map((org) => (
          <OrganizationInvitationCard
            organizationId={org}
            userId={user.uid}
            key={org}
            decisionCallback={() => setloadingState('initial')}
          />
        ))}
      </Box>
      )}
    </Box>
  );
}

function OrganizationCard(props) {
  const { organizationId } = props;
  const [loadingState, setloadingState] = useState('initial');
  const [organization, setorganization] = useState(undefined);
  const db = useFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadOrga() {
      const orgRef = doc(db, 'organizations', organizationId);
      const orgSnap = await getDoc(orgRef);
      setorganization(orgSnap.data());
      setloadingState('loaded');
    }
    if (loadingState === 'initial') {
      setloadingState('loading');
      loadOrga();
    }
  }, [db, loadingState, organizationId]);

  return (
    <Paper
      onClick={() => {
        navigate('organization', {
          state: {
            organization: { ...organization, id: organizationId },
          },
        });
      }}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        gap: 3,
        minWidth: 277,
      }}
    >
      {loadingState !== 'loaded' && (<CircularProgress />)}
      {loadingState === 'loaded'
      && (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        gap: 1,
      }}
      >
        <Typography component="span" variant="h6">{organization.name}</Typography>
      </Box>
      )}
    </Paper>
  );
}
