import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useFirestore } from 'reactfire';
import {
  collection, query, getDocs,
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import OrgApp from './OrgApp';

export default function OrgApps(props) {
  const { organizationId, member } = props;
  const [loadingState, setloadingState] = useState('initial');
  const [apps, setapps] = useState(undefined);
  const db = useFirestore();
  const { t } = useTranslation();

  useEffect(() => {
    async function loadApps() {
      const linkTo = (id) => {
        switch (id) {
          case 'alcoholometry':
            return 'https://alcoholometry.labox-apps.com';
          case 'digitank-tanker-trucks':
            return 'https://tt.digitank.labox-apps.com';
          case 'digitank-gauged-containers':
            return 'https://gc.digitank.labox-apps.com';
          case 'digitank-transporter':
            return 'https://tt.digitank.labox-apps.com';
          case 'digitank-cleaning-certificates':
            return 'https://cc.digitank.labox-apps.com';
          default:
            return '';
        }
      };

      const nameOf = (id) => t(`app_name.${id}`);
      const colRef = collection(db, 'organizations', organizationId, 'apps');
      const q = await query(colRef);
      const colSnap = await getDocs(q);
      const newApps = [];
      colSnap.forEach((doc) => {
        newApps.push({
          ...doc.data(), id: doc.id, link: linkTo(doc.id), name: nameOf(doc.id),
        });
      });
      setapps(newApps);
      setloadingState('loaded');
    }
    if (loadingState === 'initial') {
      setloadingState('loading');
      loadApps();
    }
  }, [db, loadingState, organizationId, t]);

  if (loadingState !== 'loaded') {
    return <CircularProgress />;
  }

  return (

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'stretch',
        justifyContent: 'center',
        gap: 2,
        width: '100%',
      }}
    >
      {apps.map((app) => (
        <OrgApp app={app} key={app.id} organizationId={organizationId} member={member} />
      ))}
    </Box>
  );
}
