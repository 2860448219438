import React from 'react';
import {
  IconButton, Menu, MenuItem, Link as MUILink,
} from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CalculateIcon from '@mui/icons-material/Calculate';
import { useTranslation } from 'react-i18next';
import WaterDropIcon from '@mui/icons-material/WaterDrop';

function AppsMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirect = (url) => {
    window.open(url, '_blank');
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="account"
        onClick={handleClick}
        sx={{ p: 0, fontSize: 35 }}
        color="primary"
      >
        <AppsIcon fontSize="inherit" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 2,
          }}
          onClick={() => handleRedirect('https://alcoholometry.labox-apps.com')}
        >
          <CalculateIcon />
          <MUILink
            color="primary"
            sx={{ fontWeight: 'bold', fontSize: 12 }}
          >
            {t('app_name.alcoholometry')}
          </MUILink>
        </MenuItem>
        <MenuItem
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 2,
          }}
          onClick={() => handleRedirect('https://tt.digitank.labox-apps.com')}
        >
          <LocalShippingIcon />
          <MUILink
            color="primary"
            sx={{ fontWeight: 'bold', fontSize: 12 }}
          >
            {t('app_name.digitank-transporter')}
          </MUILink>
        </MenuItem>
        <MenuItem
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 2,
          }}
          onClick={() => handleRedirect('https://gc.digitank.labox-apps.com')}
        >
          <WarehouseIcon />
          <MUILink
            color="primary"
            sx={{ fontWeight: 'bold', fontSize: 12 }}
          >
            {t('app_name.digitank-gauged-containers')}
          </MUILink>
        </MenuItem>
        <MenuItem
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 2,
          }}
          onClick={() => handleRedirect('https://cc.digitank.labox-apps.com')}
        >
          <WaterDropIcon />
          <MUILink
            color="primary"
            sx={{ fontWeight: 'bold', fontSize: 12 }}
          >
            {t('app_name.digitank-cleaning-certificates')}
          </MUILink>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default AppsMenu;
