import React from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LanguageSelectToggle from './LanguageSelectToggle';

export default function LanguageSelect(props) {
  const { t } = useTranslation();
  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexGrow: 1,
        gap: 3,
      }}
    >
      <Typography component="span" variant="h6">
        {t('settings.select_language')}
      </Typography>
      <LanguageSelectToggle />
    </Paper>
  );
}
