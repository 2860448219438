import React from 'react';
import useGlobal from 'global-state/store';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function GlobalSnackbar() {
  const [globalState, globalActions] = useGlobal();

  return (
    <Snackbar
      open={globalState.globalSnackbar.display}
      autoHideDuration={15000}
      onClose={() => globalActions.closeSnackbar()}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}
    >
      <Alert severity={globalState.globalSnackbar.severity} sx={{ p: 5 }}>
        {globalState.globalSnackbar.message}
      </Alert>
    </Snackbar>
  );
}
