import React, { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import {
  Box, Button, CircularProgress, IconButton, Paper, Typography,
} from '@mui/material';
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import useGlobal from 'global-state/store';
import { useTranslation } from 'react-i18next';
import {
  doc, getDoc, arrayUnion, arrayRemove, writeBatch,
} from 'firebase/firestore';

function OrganizationInvitationCard({ organizationId, userId, decisionCallback }) {
  const db = useFirestore();
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();

  const [organization, setOrganization] = useState(null);
  const [loadingState, setloadingState] = useState('initial');

  useEffect(() => {
    const fetchOrganization = async () => {
      const orgRef = doc(db, 'organizations', organizationId);
      const orgDoc = await getDoc(orgRef);

      if (orgDoc.exists) {
        const data = orgDoc.data();
        setOrganization({ ...data, id: orgRef.id });
        setloadingState('loaded');
      } else {
        setloadingState('loaded');
        globalActions.setSnackbarMessage({
          message: 'The organization you were invited to no longer exists.',
          severity: 'error',
        });
      }
    };

    if (loadingState === 'initial') {
      setloadingState('loading');
      fetchOrganization();
    }
  }, [db, globalActions, loadingState, organizationId, userId]);

  const handleAcceptInvitation = async () => {
    try {
      const userPrivateDataRef = doc(db, 'users', userId, 'private', 'data');
      const memberRef = doc(db, 'organizations', organizationId, 'members', userId, 'data', 'membership');
      const batch = writeBatch(db);
      batch.update(userPrivateDataRef, {
        organizations: arrayUnion(organizationId),
        organizationInvitations: arrayRemove(organizationId),
      });
      batch.update(memberRef, {
        membershipState: 'accepted',
      });
      await batch.commit();
      globalActions.setOrganizations([...globalState.organizations, organizationId]);
      decisionCallback();
      globalActions.setSnackbarMessage({ message: t('organizations.invitation_accepted'), severity: 'success' });
    } catch (error) {
      globalActions.setSnackbarMessage({
        message: `An error occurred while accepting the invitation:${error.message}`,
        severity: 'error',
      });
    }
  };

  const handleRejectInvitation = async () => {
    try {
      const userPrivateDataRef = doc(db, 'users', userId, 'private', 'data');
      const memberRef = doc(db, 'organizations', organizationId, 'members', userId, 'data', 'membership');
      const batch = writeBatch(db);
      batch.update(userPrivateDataRef, {
        organizationInvitations: arrayRemove(organizationId),
      });
      batch.update(memberRef, {
        membershipState: 'rejected',
      });
      await batch.commit();
      decisionCallback();
      globalActions.setSnackbarMessage({ message: t('organizations.invitation_rejected'), severity: 'success' });
    } catch (error) {
      globalActions.setSnackbarMessage({
        message: 'An error occurred while rejecting the invitation.',
        severity: 'error',
      });
    }
  };

  return (
    <Paper sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 277,
      gap: 2,
    }}
    >
      {loadingState !== 'loaded' ? (
        <CircularProgress />
      ) : (
        <>
          <Box>
            <Typography variant="h6">
              {organization?.name}
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 2,
          }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={handleAcceptInvitation}
              startIcon={<CheckCircleOutline />}
            >
              {t('accept')}
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={handleRejectInvitation}
              startIcon={<HighlightOff />}
            >
              {t('refuse')}
            </Button>
          </Box>
        </>
      )}
    </Paper>
  );
}

export default OrganizationInvitationCard;
