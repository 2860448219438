import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

export default function PDFView(props) {
  const { path } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ _pdfInfo }) => {
    setNumPages(_pdfInfo.numPages);
  };

  const onDocumentLoadError = () => {
    // #TODO gotta do something here
    // eslint-disable-next-line no-console
    console.log('error');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap: 1,
      }}
    >
      <Document
        file={path}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
      >
        {[...Array(numPages).keys()]
          .map((x, i) => i + 1)
          .map((page) => <Page key={page} pageNumber={page} />)}
      </Document>
      {/* <Box sx={{
        display: 'flex', justifyContent: 'space-around', gap: 3, alignItems: 'center',
      }}
      >
        <Button
          disabled={pageNumber === 1}
          size="small"
          variant="contained"
          color="primary"
          onClick={() => setPageNumber((prevNumber) => prevNumber - 1)}
        >
          {'<'}
        </Button>
        Page
        {' '}
        {pageNumber}
        {' '}
        /
        {' '}
        {numPages}
        <Button
          disabled={pageNumber === numPages}
          size="small"
          variant="contained"
          color="primary"
          onClick={() => setPageNumber((prevNumber) => prevNumber + 1)}
        >
          {'>'}
        </Button>
      </Box> */}
    </Box>
  );
}
