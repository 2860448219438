import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useFirestore, useUser } from 'reactfire';
import {
  doc, getDocs, collection, query, getDoc, where,
} from 'firebase/firestore';
import Members from './Members';
import MyMembership from '../MyMembership';
import OrgApps from './OrgApps';

export default function Organization({ organization }) {
  const [loadingState, setloadingState] = useState('initial');
  const db = useFirestore();
  const { data: user } = useUser();
  const { t } = useTranslation();
  const [members, setMembers] = useState(undefined);
  const [pendingMembers, setpendingMembers] = useState(undefined);

  useEffect(() => {
    async function loadMembers() {
      const colRef = collection(db, 'organizations', organization.id, 'members');
      const q = await query(colRef);
      const colSnap = await getDocs(q);
      const newMembers = [];
      colSnap.forEach((m) => {
        newMembers.push({ ...m.data(), id: m.id });
      });
      loadUsers(newMembers);
      loadPendingMembers();
    }

    async function loadPendingMembers() {
      const pendingInviteQuery = await query(
        collection(db, 'pendingOrganizationInvitations'),
        where('organizationId', '==', organization.id),
        where('consumed', '==', false),
      );
      const colSnap = await getDocs(pendingInviteQuery);
      const newPendingMembers = [];
      colSnap.forEach((m) => {
        newPendingMembers.push({ ...m.data(), id: m.id });
      });
      setpendingMembers(newPendingMembers);
    }

    async function loadUsers(firstVersionMembers) {
      const newMembers = await Promise.all(
        firstVersionMembers.map(async (m) => {
          const udocRef = doc(db, 'users', m.id);
          const udocSnap = await getDoc(udocRef);
          if (udocSnap.exists()) {
            return { ...m, email: udocSnap.data().email };
          }
          return { ...m, email: t('organizations.user_removed') };
        }),
      );
      loadMemberships(newMembers);
    }

    async function loadMemberships(secondVersionMembers) {
      const newMembers = await Promise.all(
        secondVersionMembers.map(async (m) => {
          const udocRef = doc(db, 'organizations', organization.id, 'members', m.id, 'data', 'membership');
          const udocSnap = await getDoc(udocRef);
          return { ...m, membershipState: udocSnap.data().membershipState };
        }),
      );
      setMembers(newMembers);
      setloadingState('loaded');
    }

    if (loadingState === 'initial' && user?.uid !== undefined) {
      setloadingState('loading');
      loadMembers();
    }
  }, [db, loadingState, organization.id, t, user?.uid]);

  if (loadingState !== 'loaded' || user?.uid === undefined) {
    return <CircularProgress />;
  }

  return (
    <Box
      sx={{
        p: 3,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: 3,
      }}
    >
      <Members
        organizationId={organization.id}
        members={members}
        setMembers={setMembers}
        pendingMembers={pendingMembers}
        setpendingMembers={setpendingMembers}
      />
      <Typography component="span" variant="h4">Applications</Typography>
      <OrgApps organizationId={organization.id} members={members} />
      <Typography component="span" variant="h4">{t('organizations.membership')}</Typography>
      <MyMembership organizationId={organization.id} member={members.find((m) => m.id === user.uid)} />
    </Box>
  );
}
