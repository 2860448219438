import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MemberRoleSelect from './MemberRoleSelect';
import DeleteMemberButton from './DeleteMemberButton';

export default function Member(props) {
  const { t } = useTranslation();
  const {
    organizationId, member, onDeleted, active = false, userExists = true,
  } = props;

  return (
    <Paper
      elevation={0}
      sx={{
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        border: active ? '0.5px solid' : '',
        borderColor: 'ourGreen.main',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: { xs: 'flex-start', md: 'space-between' },
          gap: 1,
          width: '100%',
        }}
      >
        <Typography sx={{
          textAlign: 'left',
          fontWeight: 'bold',
          wordBreak: 'break-all', // Allows breaking at any character
          display: 'inline-block', // Ensures the element respects the maxWidth
          hyphens: 'auto', // Attempt to hyphenate at appropriate hyphenation points
          lineHeight: 'normal',
        }}
        >
          {member.email}
        </Typography>
        <Box sx={{
          display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center',
        }}
        >
          <Typography component="span">{`${t('organizations.role')}:`}</Typography>
          <MemberRoleSelect
            userExists={userExists}
            defaultValue={member.role}
            organizationId={organizationId}
            userId={member.id}
          />
          <DeleteMemberButton
            onDeleted={onDeleted}
            memberId={member.id}
            organizationId={organizationId}
          />
        </Box>
      </Box>
    </Paper>
  );
}
