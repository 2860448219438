import React, { useState } from 'react';
import {
  Button, Dialog, DialogContent, DialogActions, Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import useGlobal from 'global-state/store';
import { LoadingButton } from '@mui/lab';

function DeleteMemberButton({ memberId, onDeleted, organizationId }) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { t } = useTranslation();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const [, globalActions] = useGlobal();
  const [loading, setLoading] = useState(false);

  const handleDeleteClick = () => {
    setConfirmOpen(true);
  };

  const handleConfirmCancel = () => {
    setConfirmOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      const removeOrganizationMember = httpsCallable(functions, 'removeOrganizationMemberCallM2');
      await removeOrganizationMember({ organizationId, userId: memberId });
      globalActions.setSnackbarMessage({ message: t('organizations.member_removed'), severity: 'success' });
      onDeleted(memberId);
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `Error removing member: ${error.message}`, severity: 'error' });
    } finally {
      setConfirmOpen(false);
    }
  };

  return (
    <Box sx={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
      <Button variant="outlined" color="error" size="small" onClick={handleDeleteClick}>
        {t('delete')}
      </Button>
      <Dialog open={confirmOpen} onClose={handleConfirmCancel}>
        <DialogContent>
          {t('organizations.delete_confirm')}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmCancel} color="primary">
            {t('no')}
          </Button>
          <LoadingButton loading={loading} onClick={handleConfirmDelete} color="error" autoFocus>
            {t('yes')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default DeleteMemberButton;
