import React, { useState } from 'react';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Box, Typography,
} from '@mui/material';
import { useFunctions } from 'reactfire';
import { useFormik } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';

function CreateNewOrganizationButton({ onCreate }) {
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = 'Name is required';
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const createNewOrganization = httpsCallable(functions, 'createNewOrganizationCallM2');
        await createNewOrganization({ name: values.name });
        globalActions.setSnackbarMessage({ message: t('organizations.created'), severity: 'success' });
        onCreate();
      } catch (error) {
        globalActions.setSnackbarMessage({
          message: `Error creating organization: ${error.message}`,
          severity: 'error',
        });
      } finally {
        setDialogOpen(false);
      }
    },
  });

  const handleCreateClick = () => {
    if (globalState.wsRoles.includes('admin')) {
      setDialogOpen(true);
    } else {
      navigate('/contact');
    }
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
          cursor: 'pointer',
          minWidth: 277,
        }}
        onClick={handleCreateClick}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          gap: 1,
        }}
        >
          <AddIcon fontSize="large" />
          <Typography component="span">
            {t('organizations.create_one')}
          </Typography>
        </Box>
      </Paper>
      <Dialog open={dialogOpen} onClose={handleCancel}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>{t('organizations.create_one')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.name)}
              helperText={formik.errors.name}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              {t('cancel')}
            </Button>
            <Button type="submit" color="primary" disabled={formik.isSubmitting || !formik.isValid}>
              {t('create')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default CreateNewOrganizationButton;
