import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function LanguageSelect() {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState(t('locale'));

  const handleLocaleChange = (event, newLocale) => {
    if (newLocale !== null) {
      setLocale(newLocale);
      i18n.changeLanguage(newLocale, () => {});
      localStorage.setItem('saved_locale', newLocale);
    }
  };
  return (
    <ToggleButtonGroup
      value={locale}
      exclusive
      onChange={handleLocaleChange}
      aria-label="text alignment"
    >
      <ToggleButton
        sx={{ height: 30, width: 20, fontSize: 12 }}
        value="en"
        aria-label="centered"
      >
        EN
      </ToggleButton>
      <ToggleButton
        sx={{ height: 30, width: 20, fontSize: 12 }}
        value="fr"
        aria-label="centered"
      >
        FR
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
