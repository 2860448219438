import React, { useEffect, useState } from 'react';
import {
  Button, Typography, Link as MUILink, Switch,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import Box from '@mui/material/Box';
import Refresh from '@mui/icons-material/Refresh';
import version from 'staticFiles/version.txt';
import { Link } from 'react-router-dom';

export default function Footer({ minimal = false }) {
  const { t } = useTranslation();
  const [appVersion, setappVersion] = useState('');

  const handleSwitchLongPollingChange = (event) => {
    localStorage.setItem('forceLongPolling', event.target.checked.toString());
    window.location.reload();
  };

  useEffect(() => {
    fetch(version)
      .then((r) => r.text())
      .then((text) => {
        setappVersion(text);
      });
  }, []);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      mt: 6,
      mb: 3,
    }}
    >

      {minimal === false && (
      <Typography component="span" variant="body2" style={{ marginTop: 10, width: '100%' }}>
        {t('welcome.contact_footer')}
        {' '}

        <MUILink
          component={Link}
          to="/contact"
          color="primary"
          sx={{ fontWeight: 'bold' }}
        >
          contact@labox-apps.com
        </MUILink>
      </Typography>
      )}

      {minimal === false && (
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: 1,
          mt: 1,
        }}
      >
        <Button
          sx={{ flexGrow: 1 }}
          variant="contained"
          color="primary"
          size="small"
          component={Link}
          to="/cgu"
          endIcon={<PdfIcon fontSize="small" />}
        >
          {t('welcome.gtcu')}
        </Button>
        <Button
          sx={{ flexGrow: 1 }}
          variant="contained"
          component={Link}
          to="/cgv"
          color="primary"
          size="small"
          endIcon={<PdfIcon fontSize="small" />}
        >
          {t('welcome.cgv')}
        </Button>
        <Button
          sx={{ flexGrow: 1 }}
          variant="contained"
          component={Link}
          to="/privacy-policy"
          color="primary"
          size="small"
          endIcon={<PdfIcon fontSize="small" />}
        >
          {t('welcome.privacy_policy')}
        </Button>
      </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        <Typography variant="caption">
          {t('settings.app_version', { appVersion })}
        </Typography>

        <Button
          variant="text"
          size="small"
          onClick={() => window.location.reload()}
          endIcon={<Refresh />}
        >
          <Typography variant="caption">
            {t('reload')}
          </Typography>
        </Button>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        mt: 3,
      }}
      >
        <Typography variant="caption">
          {t('long_polling_switch')}
        </Typography>
        <Switch
          size="small"
          checked={localStorage.getItem('forceLongPolling') === 'true'}
          onChange={handleSwitchLongPollingChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Box>
    </Box>
  );
}
