import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Member from './Member';
import AddMemberButton from './AddMemberButton';

export default function Members(props) {
  const {
    organizationId, members, setMembers, pendingMembers, setpendingMembers,
  } = props;
  const { t } = useTranslation();

  const activeMembers = members.filter((m) => m.membershipState === 'accepted');
  const activeMemberEmails = activeMembers.map((m) => m.email);
  const invitedMembers = members.filter((m) => m.membershipState === 'invited'
    && !activeMemberEmails.includes(m.email));

  const handleDelete = (memberId) => {
  // Check if the memberId exists in the members array
    const memberExists = members.some((m) => m.id === memberId);
    if (memberExists) {
      const newMembers = members.filter((m) => m.id !== memberId);
      setMembers(newMembers);
    } else {
      const newPendingMembers = pendingMembers.filter((m) => m.id !== memberId);
      setpendingMembers(newPendingMembers);
    }
  };

  const handleAdded = (member) => {
    if (member.userExists) {
      const newMembers = [...members];
      newMembers.push(member);
      setMembers(newMembers);
    } else {
      const newMembers = [...pendingMembers];
      newMembers.push(member);
      setpendingMembers(newMembers);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: 2,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          alignItems: 'stretch',
          justifyContent: 'center',
          gap: 1,
          width: '100%',
        }}
      >
        <Typography component="span" variant="h6" sx={{ alignSelf: 'flex-start', fontWeight: 'bold' }}>
          {t('organizations.active_members')}
        </Typography>
        {activeMembers.map((member) => (
          <Member
            active
            member={member}
            key={member.id}
            organizationId={organizationId}
            onDeleted={handleDelete}
          />
        ))}
        <Typography component="span" variant="h6" sx={{ alignSelf: 'flex-start', fontWeight: 'bold' }}>
          {t('organizations.invited_members')}
        </Typography>
        {invitedMembers.map((member) => (
          <Member
            member={member}
            key={member.id}
            organizationId={organizationId}
            onDeleted={handleDelete}
          />
        ))}
        <AddMemberButton organizationId={organizationId} onAdded={handleAdded} />
      </Box>
      {pendingMembers.length > 0 && (
      <Typography component="span" variant="h6" sx={{ alignSelf: 'flex-start', fontWeight: 'bold' }}>
        {t('organizations.pending_members')}
      </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          alignItems: 'stretch',
          justifyContent: 'center',
          gap: 1,
          width: '100%',
        }}
      >
        {pendingMembers.length > 0 && pendingMembers.map((member) => (
          <Member
            userExists={false}
            member={member}
            key={member.id}
            organizationId={organizationId}
            onDeleted={handleDelete}
          />
        ))}
      </Box>
    </Box>
  );
}
